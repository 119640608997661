html, body, .viewport {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "David Libre";
  background-color: black;
  color: #FFDF2D;
}

.container, #root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  align-content: center;
}

.teaser {
  font-size: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.learn-more {
  padding-top: 20px;
  font-size: 20px;
  text-align: center;
}

.learn-more a {
  color: #FFDF2D;
}
